<template>
  <div class="edrea_tm_section animated" id="home">
    <div class="section_inner">
      <div class="edrea_tm_contact">
        <div class="edrea_tm_main_title">
          <h1>Hi, I'm <b2 class="coloring">Ty</b2></h1>
        </div>
        <div class="wrapper">
          <div class="left">
            <div class="text">
              <p>
                I've been a fullstack developer for about 10 years going now,
                with my primary focus on Web and Game development.
              </p>
              <br/>
              <p>
                If you'd like to get in touch, feel free to drop me an email or connect with me on LinkedIn.
              </p>
              <br/>
              <br/>
              <u>Skills Summary</u>
              <p>
                Gamedev: Unity3D, Multiplayer, AR/VR
              </p>
              <p>
                Primary Stack: .Net Core/Framework(C#), Javascript/Typescript, Vue
              </p>
              <p>
                DB: MsSql, Postgres
              </p>
              <p>
                Cloud: Azure, AWS
              </p>
            </div>
            <div class="text">


            </div>

          </div>

          <div class="left" style="padding-left:32px; padding-right:32px; padding-top:5px;">
            <ul>
              <li>
                <div class="list_inner">
                  <i class="icon-mail-1"></i>
                  <span
                  ><a href="mailto:tycoon@tycoonliew.com"
                  >tycoon@tycoonliew.com</a
                  ></span
                  >
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <i class="icon-linkedin-1"></i>
                  <span
                  ><a href="https://linkedin.com/in/tycoon-liew">linkedin.com/in/tycoon-liew</a></span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
<!--      <div class="edrea_tm_home">-->
<!--        <h3 class="name">Tycoon Liew</h3>-->
<!--        <h3 class="job">-->
<!--          Full Stack Developer-->
<!--        </h3>-->
<!--        <div class="edrea_tm_button transition_link">-->
<!--          <a href="#contact" @click="navFuntions.activeSection('about')"-->
<!--            >About Me</a-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";
import AnimationText from "./AnimationText.vue";

export default {
  name: `HomeComponent`,
  data() {
    return {
      navFuntions,
    };
  },
  components: { AnimationText },
};
</script>
