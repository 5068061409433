<template>
  <div class="edrea_tm_section" id="contact">
    <div class="section_inner">
      <div class="edrea_tm_contact">
        <div class="edrea_tm_main_title">
          <h3>Hi,</h3>
        </div>
        <div class="wrapper">
          <div class="left">
            <div class="text">
              <p>
                I've been working on software for about 10 years now,
                with my primary focus being AR/VR, Game development and Web development.
              </p>
              <p>
                If you'd like to get in touch, feel free to drop me an email or connect with me on LinkedIn.
              </p>
            </div>
            <div class="text">

            <!--            <p>-->
            <!--              Tech: Unity3D, Multiplayer, AR/VR-->
            <!--            </p>-->
            <!--            <p>-->
            <!--            Webdev: JS/TS, Vue, .Net/C#,-->
            <!--            </p>-->
            <!--            <p>-->
            <!--            DB: MsSql, MySql, Postgres, MongoDb-->
            <!--            </p>-->
            <!--            <p>-->
            <!--            Cloud: Azure, AWS-->
            <!--            </p>-->
              </div>

            </div>

          <div class="left">
            <ul>
              <li>
                <div class="list_inner">
                  <i class="icon-mail-1"></i>
                  <span
                    ><a href="mailto:tycoon@tycoonliew.com"
                      >tycoon@tycoonliew.com</a
                    ></span
                  >
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <i class="icon-linkedin-1"></i>
                  <span
                    ><a href="https://linkedin.com/in/tycoon-liew">linkedin.com/in/tycoon-liew</a></span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `ContactComponent`,
};
</script>
