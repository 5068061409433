<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
<!--          <div class="image">-->
<!--            <img src="img/thumbs/1-1.jpg" alt="" />-->
<!--            <div class="main" data-img-url="img/about/1.jpg"></div>-->
<!--          </div>-->
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">Hi I'm Tycoon</h3>
          </div>
          <div class="text">
            <p>
              I've been working on software for about 10 years now,
              with my primary focus being AR/VR, Game development and Web development.
            </p>
            <p>
              I enjoy working towards great user experiences and solving problems.
            </p>
<!--            <p>-->
<!--              Tech: Unity3D, Multiplayer, AR/VR-->
<!--            </p>-->
<!--            <p>-->
<!--            Webdev: JS/TS, Vue, .Net/C#,-->
<!--            </p>-->
<!--            <p>-->
<!--            DB: MsSql, MySql, Postgres, MongoDb-->
<!--            </p>-->
<!--            <p>-->
<!--            Cloud: Azure, AWS-->
<!--            </p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
